
// Bootstrap
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootswatch/flatly/variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~bootswatch/flatly/bootswatch";

@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css');

body {
  padding-top: 5em;
}

.badge-error {
  background-color: #900 !important;
  color: #ffffff;
}

.glyphicon-spin {
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
